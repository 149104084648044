import React from 'react';

const ErrorPage = () => {
    return (
        <div style={{'margin': '0 auto', 'width': '80%'}}>
            <h1>Error 404 - This page doesn't exist</h1>
        </div>
    )
}

export default ErrorPage;